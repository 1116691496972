import { useToggle } from "@mantine/hooks"
import InputForm, { DuplicateAnalysisInput } from "@/prospectAnalysis/InputForm"
import ProgressDisplay from "@/prospectAnalysis/ProgressDisplay"
import { co } from "flowz-shared"
import { MAX_REQUEST_COUNT, RSocketConnector } from "rsocket-core"
import { WellKnownMimeType } from "rsocket-composite-metadata"
import { WebsocketClientTransport } from "rsocket-websocket-client"
import { RSocketRequester } from "rsocket-messaging"
import { RxRequestersFactory } from "rsocket-adapter-rxjs"
import { scan } from "rxjs"
import CBORCodec from "@/rsocket/CBORCodec"
import { useState } from "react"
import ProspectAnalysisResults from "@/ui/ProspectAnalysisResults"
import { useAnalytics } from "@/analytics"
import ProspectAnalysis = co.getflowz.types.ProspectAnalysis
import ProspectAnalysisRequest = co.getflowz.types.ProspectAnalysisRequest
import RSocketRoutes = co.getflowz.constants.RSocketRoutes
import { getSHA256Hash } from "boring-webcrypto-sha256"

enum FlowStep {
  DATA_INPUT = "dataInput",
  PROGRESS_DISPLAY = "progressDisplay",
  RESULTS_DISPLAY = "resultsDisplay",
}

const analyzeProspects = async ({
  prospectsCsv,
  lemlistApiKey,
}: DuplicateAnalysisInput) => {
  const connector = new RSocketConnector({
    setup: {
      dataMimeType: WellKnownMimeType.APPLICATION_CBOR.string,
      metadataMimeType:
        WellKnownMimeType.MESSAGE_RSOCKET_COMPOSITE_METADATA.string,
    },
    fragmentation: {
      maxOutboundFragmentSize: 65000,
    },
    transport: new WebsocketClientTransport({
      url: import.meta.env.TRY_BACKEND_URL,
      wsCreator: (url) => new WebSocket(url),
      debug: true,
    }),
  })

  const rsocket = await connector.connect()

  const client = RSocketRequester.wrap(rsocket)

  const data = await prospectsCsv.arrayBuffer()

  return client
    .route(RSocketRoutes.ANALYZE_PROSPECTS)
    .request(
      RxRequestersFactory.requestStream<
        ProspectAnalysisRequest,
        ProspectAnalysis
      >(
        new ProspectAnalysisRequest(data, lemlistApiKey),
        new CBORCodec(),
        new CBORCodec(),
        MAX_REQUEST_COUNT,
      ),
    )
    .pipe(scan((set, data) => [...set, data], [] as ProspectAnalysis[]))
}

const App = () => {
  const analytics = useAnalytics()

  const [step, advance] = useToggle(Object.values(FlowStep))

  const [results, setResults] = useState<ProspectAnalysis[] | null>(null)

  switch (step) {
    case FlowStep.DATA_INPUT:
      return (
        <InputForm
          onSubmit={async (input) => {
            analytics.identify(await getSHA256Hash(input.lemlistApiKey))
            analytics.prospectsSubmitted()
            const analyzeProspectsResultStream = await analyzeProspects(input)
            analyzeProspectsResultStream.subscribe({
              next: (data) => setResults(data),
              error: (err) => console.log("Subscription error:", err),
              complete: advance,
            })
            advance()
          }}
        />
      )
    case FlowStep.PROGRESS_DISPLAY:
      return <ProgressDisplay />
    case FlowStep.RESULTS_DISPLAY:
      return results && <ProspectAnalysisResults analysis={results} />
  }
}

export default App
