import { Loader, Text } from "@mantine/core"
import { co } from "flowz-shared"
import UITestIds = co.getflowz.constants.UITestIds

const ProgressDisplay = () => {
  return (
    <div data-testid={UITestIds.progressDisplay}>
      <Loader />
      <Text c="dimmed">Fetching lemlist data</Text>
    </div>
  )
}

export default ProgressDisplay
