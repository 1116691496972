import { Codec } from "rsocket-messaging"
import { WellKnownMimeType } from "rsocket-composite-metadata"
import { decode as decodeCbor, encode as encodeCbor } from "cbor-x"

export default class CBORCodec implements Codec<any> {
  readonly mimeType: string = WellKnownMimeType.APPLICATION_CBOR.string

  decode(buffer: Buffer): any {
    return decodeCbor(buffer)
  }

  encode(entity: any): Buffer {
    return encodeCbor(entity)
  }
}
