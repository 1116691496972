import { createContext, ReactNode, useContext, useMemo } from "react"
import { co } from "flowz-shared"
import maybeSegment from "./segment"
import AnalyticsFactory = co.getflowz.analytics.AnalyticsFactory

const AnalyticsContext = createContext(AnalyticsFactory.DEFAULT)
export const useAnalytics = () => useContext(AnalyticsContext)

interface AnalyticsProps {
  enabled: boolean
  writeKey: string
  children: ReactNode
}

export const Analytics = ({ enabled, writeKey, children }: AnalyticsProps) => {
  const analytics = useMemo(
    () => AnalyticsFactory.from(maybeSegment(enabled, writeKey)),
    [enabled, writeKey],
  )

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  )
}
