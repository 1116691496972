import { AnalyticsBrowser } from "@segment/analytics-next"

const maybeSegment = (enabled: boolean, writeKey: string) =>
  enabled
    ? AnalyticsBrowser.load({
        writeKey,
      })
    : null

export default maybeSegment
