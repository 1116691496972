import React from "react"
import ReactDOM from "react-dom/client"
import { MantineProvider } from "@mantine/core"
import App from "@/App"
import { Analytics } from "@/analytics"

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <MantineProvider withGlobalStyles withNormalizeCSS withCSSVariables>
      <Analytics
        enabled={import.meta.env.TRY_SEGMENT_ENABLED}
        writeKey={import.meta.env.TRY_SEGMENT_KEY}
      >
        <App />
      </Analytics>
    </MantineProvider>
  </React.StrictMode>,
)
