import ProspectFileSelector from "@/ui/form/ProspectFileSelector"
import { FormEvent, useState } from "react"
import { Button, Input, PasswordInput } from "@mantine/core"
import { IconArrowRight } from "@tabler/icons-react"
import { co } from "flowz-shared"
import UITestIds = co.getflowz.constants.UITestIds

// TODO
// - disable submit until all data is filled
// - tooltip on lemlist api key field

// TODO Type in common with the backend, create common definition?
export type DuplicateAnalysisInput = {
  prospectsCsv: File
  lemlistApiKey: string
}

interface DuplicateAnalysisInputFormProps {
  onSubmit: (input: DuplicateAnalysisInput) => void
}

const DuplicateAnalysisInputForm = ({
  onSubmit,
}: DuplicateAnalysisInputFormProps) => {
  const [prospectsCsv, setProspectsCsv] = useState<File | null>(null)
  const [lemlistApiKey, setLemlistApiKey] = useState<string | null>(null)

  const fireSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault()

    if (!prospectsCsv || !lemlistApiKey) return

    onSubmit({
      prospectsCsv,
      lemlistApiKey,
    })
  }

  return (
    <form onSubmit={fireSubmit}>
      <Input.Wrapper label="Prospect list">
        <ProspectFileSelector onFileSelected={setProspectsCsv} />
      </Input.Wrapper>
      <PasswordInput
        data-testid={UITestIds.lemlistApiKeyInput}
        label="Lemlist API key"
        onChange={(ev) => setLemlistApiKey(ev.target.value)}
      />
      <Button
        data-testid={UITestIds.analyzeProspectsButton}
        type="submit"
        rightIcon={<IconArrowRight size={16} />}
      >
        Analyze
      </Button>
    </form>
  )
}

export default DuplicateAnalysisInputForm
