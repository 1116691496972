import { co } from "flowz-shared"
import ProspectMatchStatusBadge from "@/ui/ProspectMatchStatusBadge"
import { Button, Table } from "@mantine/core"
import { IconDownload } from "@tabler/icons-react"
import { stringify } from "csv-stringify/sync"
import ProspectAnalysis = co.getflowz.types.ProspectAnalysis
import MatchStatus = co.getflowz.types.MatchStatus
import UITestIds = co.getflowz.constants.UITestIds
import { useAnalytics } from "@/analytics"

interface ProspectAnalysisResultsProps {
  analysis: ProspectAnalysis[]
}

const toTable = (result: ProspectAnalysis[]): string[][] => [
  [
    "Status",
    "Found where?",
    "Status in campaign",
    ...Object.keys(result[0].prospect),
  ],
  ...result.map(({ match, prospect }) => [
    MatchStatus.valueOf(match.status).value,
    match.campaign?.name || "",
    match.outreachState || "",
    ...Object.keys(prospect).map((column) => prospect[column]),
  ]),
]

const downloadResults = (results: ProspectAnalysis[]) => {
  const content = new Blob([stringify(toTable(results))], { type: "text/csv" })
  const download = document.createElement("a")
  download.setAttribute("href", URL.createObjectURL(content))
  download.setAttribute("download", "prospect-analysis.csv")
  download.style.display = "none"

  document.body.appendChild(download)
  download.click()
  document.body.removeChild(download)
}

const ProspectAnalysisResults = ({
  analysis,
}: ProspectAnalysisResultsProps) => {
  const analytics = useAnalytics()

  // TODO Yikes - solution: ProspectAnalysis[] -> SomeType that can have `newCount` and `existingCount`
  // TODO This gets called twice for some reason
  analytics.resultsReceived(
    analysis.filter(
      (analysis) =>
        MatchStatus.valueOf(analysis.match.status) == MatchStatus.NEW,
    ).length,
    analysis.filter(
      (analysis) =>
        MatchStatus.valueOf(analysis.match.status) == MatchStatus.EXISTING,
    ).length,
  )

  // TODO If analysis empty, show empty status
  if (analysis.length == 0) return null

  return (
    <div data-testid={UITestIds.analysisResult}>
      <Button
        data-testid={UITestIds.downloadCsvButton}
        type="button"
        variant="outline"
        rightIcon={<IconDownload size={16} />}
        onClick={() => {
          analytics.resultsCSVDownloaded()
          downloadResults(analysis)
        }}
      >
        Download CSV
      </Button>
      <Table data-testid={UITestIds.prospectsTable}>
        <thead>
          <tr>
            <th>Status</th>
            <th>Found where?</th>
            <th>Status in campaign</th>
            {Object.keys(analysis[0].prospect).map((column) => (
              <th key={`header_${column}`}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {analysis.map(({ prospect, match }, index) => (
            <tr key={`prospect_${index}`}>
              <td>
                <ProspectMatchStatusBadge
                  matchStatus={MatchStatus.valueOf(match.status)}
                />
              </td>
              <td>{match.campaign?.name || "-"}</td>
              <td>{match.outreachState || "-"}</td>
              {Object.keys(prospect).map((column) => (
                <td key={`prospect_field_${column}`}>{prospect[column]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default ProspectAnalysisResults
