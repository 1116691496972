import { Badge } from "@mantine/core"
import { co } from "flowz-shared"
import MatchStatus = co.getflowz.types.MatchStatus

interface ProspectMatchStatusBadgeProps {
  matchStatus: MatchStatus
}

const matchStatusColorMap = {
  [MatchStatus.NEW.value]: "lime",
  [MatchStatus.EXISTING.value]: "orange",
}

const ProspectMatchStatusBadge = ({
  matchStatus,
}: ProspectMatchStatusBadgeProps) => (
  <Badge color={matchStatusColorMap[matchStatus.value]} variant="filled">
    {matchStatus.value}
  </Badge>
)

export default ProspectMatchStatusBadge
