import { useDropzone } from "react-dropzone"
import { Center } from "@mantine/core"
import { IconDownload, IconFileTypeCsv } from "@tabler/icons-react"
import { co } from "flowz-shared"
import UITestIds = co.getflowz.constants.UITestIds

interface ProspectFileUploadProps {
  onFileSelected: (file: File) => void
}

const ProspectFileSelector = ({ onFileSelected }: ProspectFileUploadProps) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/csv": [".csv"],
    },
    multiple: false,
    onDrop: ([file, ..._]) => onFileSelected(file),
  })

  return (
    <Center data-testid={UITestIds.prospectsFileInputArea} {...getRootProps()}>
      {acceptedFiles.length > 0 ? (
        <>
          <IconFileTypeCsv />
          {acceptedFiles[0].name}
        </>
      ) : (
        <>
          <input {...getInputProps()} />
          <IconDownload size={16} />
          Drag and drop your prospect CSV here or click to browse.
        </>
      )}
    </Center>
  )
}

export default ProspectFileSelector
